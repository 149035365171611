// import React from "react";
// import { Link } from "react-router-dom";
// const Home = () => {
//   return (
//     <div className="dhiwise-navigation">
//       <h1>Homepage</h1>
//       <p className="headline">
//         This project was generated By{" "}
//         <a href="https://www.dhiwise.com">Dhiwise</a>. Quickly use below links
//         to navigate through all pages.
//       </p>
//       <ul>
//         <li>
//           <Link to="/seafilecombyhtmltodesignfreeversion10032023">
//             SeafilecombyhtmltodesignFREEversion10032023
//           </Link>
//         </li>
//       </ul>
//     </div>
//   );
// };
// export default Home;
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
const Home = () => {
  useEffect(() => {
    const timeout = setTimeout(() => {
      window.location.replace('https://huupe.com/');
    }, 1);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className="dhiwise-navigation">
      
      <p className="headline">
     
      </p>
    </div>
  );
};
export default Home;
